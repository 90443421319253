import * as React from 'react';
import * as GameStore from '../store/GameStore';

export interface IGameTableProps extends GameStore.IGame {
    revealCard(cardId: string): any
}

export class GameTable extends React.PureComponent<IGameTableProps> {
    public render() {
        return (
            <div className={"cn_game-table cn_row " + ((this.props.state === GameStore.GameStateEnum.Started) ? "cn_game--active" : "")}>
                {this.props.cards.map((card) => { return <Card {...card} revealCard={this.props.revealCard} key={card.cardId} /> })}
            </div>
        );
    }
}

interface ICardProps extends GameStore.ICard {
    revealCard(cardId: string): any,
}

class Card extends React.PureComponent<ICardProps> {
    public render() {
        if (this.props.revealed) {
            return (
                <div className="cn_col"><div className={"cn_game-card cn_game-card--revealed alert alert-" + this.getClassSuffix(this.props.role)}>                  
                </div></div >
            );
        }
        return (
            <div className="cn_col"><div className={"cn_game-card alert alert-" + this.getClassSuffix(this.props.role)} onClick={() => this.props.revealCard(this.props.cardId)}>
                {this.props.word}
            </div></div>
        );
    }

    private getClassSuffix(role: GameStore.CardRoleEnum) {
        switch (role) {
            case GameStore.CardRoleEnum.Red:
                return "danger";
            case GameStore.CardRoleEnum.Blue:
                return "primary";
            case GameStore.CardRoleEnum.Assassin:
                return "black";
            case GameStore.CardRoleEnum.Passerby:
                return "secondary";
            case GameStore.CardRoleEnum.Unknown:
                return "warning";
        }
    }
}