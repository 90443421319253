import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as GameStore from '../store/GameStore';
import * as signalR from '@microsoft/signalr';
import ReactGA from 'react-ga';
import { IGameTableProps, GameTable } from './GameTable';
import GamePlayers from './GamePlayers';
import GameJournal from './GameJournal';
import GameConnect from './GameConnect';
import GameHeader from './GameHeader';
import { Constants } from '../store/Constants'

// At runtime, Redux will merge together...
type GameProps =
    GameStore.IGameState // ... state we've requested from the Redux store
    & typeof GameStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ gameId: string }>; // ... plus incoming routing parameters

interface IComponentState {
    connection: signalR.HubConnection,
    isConnected: boolean,
    initialGameId: string,
    playerName: string,
}

class Game extends React.PureComponent<GameProps, IComponentState> {
    constructor(props: GameProps) {
        super(props);
        const initialGameId = (props.match.params.gameId && decodeURIComponent(props.match.params.gameId.trim())) || "";

        const connection = new signalR.HubConnectionBuilder()
            .withUrl("/api/game-hub")
            .withAutomaticReconnect()
            .build();
        this.state = {
            connection: connection,
            isConnected: false,
            initialGameId: initialGameId,
            playerName: localStorage.getItem("user-nickname") || "",
        };
        connection.on("LogToJournal", entry => {
            console.log(JSON.stringify(entry));
            this.ensureGameIsFetched();
        });
        connection.on("InitializeGame", gameId => {
            if (gameId === this.state.initialGameId) {
                this.ensureGameIsFetched();
            }
        });
        connection.start().catch(err => console.error(err.toString())); 

        ReactGA.initialize(Constants.GA_MeteringId);
        ReactGA.pageview("play/" + initialGameId);
    }

    public render() {
        return (
            <React.Fragment>
                <GameHeader game={this.props.game} gameId={this.state.initialGameId}
                    promotePlayer={this.promotePlayer.bind(this)}
                    restartGame={this.restartGame.bind(this)}
                    isLoading={this.props.isLoading} />
                {this.renderInternal()}
            </React.Fragment>
        );
    }

    public renderInternal() {
        if (this.props.game === null || !this.state.isConnected) {
            return (
                <div className="container">
                    <GameConnect playerName={this.state.playerName}
                        connectPlayer={this.connectPlayer.bind(this)}
                        changePlayerName={this.changePlayerName.bind(this)}
                        gameId={this.state.initialGameId}
                    />
                </div>
            );
        }
        const gametable: IGameTableProps = {
            revealCard: this.revealCard.bind(this),
            ...this.props.game
        }
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-9 col-xl-10">
                        <GameTable {...gametable} />
                    </div>
                    <div className="col-lg-3 col-xl-2">
                        <GamePlayers players={this.props.game.players} />
                        <GameJournal journal={this.props.game.journal} />
                    </div>
                </div>
            </div>
        );
    }

    private connectPlayer() {
        const name = this.state.playerName.trim();
        if (name.length === 0) {
            return;
        }
        localStorage.setItem("user-nickname", name);
        this.state.connection.invoke("ConnectPlayer", this.state.initialGameId, this.state.playerName)
            .then(() => this.setState({ isConnected: true }))
            .catch(err => console.error(err.toString()));
    }

    private changePlayerName(name: string) {
        this.setState({
            playerName: name
        });
    }

    private promotePlayer() {
        if (this.props.isLoading || !this.props.game) {
            return;
        }
        this.state.connection.invoke("PromotePlayerToLeader", this.props.gameId)
            .catch(err => console.error(err.toString()));
    }

    private restartGame() {
        if (this.props.isLoading || !this.props.game) {
            return;
        }
        this.state.connection.invoke("RestartGame", this.props.gameId)
            .catch(err => console.error(err.toString()));
    }

    private revealCard(cardId: string) {
        const g = this.props.game;
        if (this.props.isLoading || g === null || g.state !== GameStore.GameStateEnum.Started) {
            return;
        }
        var card = g.cards.find((c) => c.cardId === cardId);
        if (card && !card.revealed) {
            this.state.connection.invoke("RevealCard", this.props.gameId, cardId)
                .catch(err => console.error(err.toString()));
        }
    }

    private ensureGameIsFetched() {
        this.props.requestGame(this.state.initialGameId, this.state.connection.connectionId || "");
    }
}

export default connect(
    (state: ApplicationState) => state.gameState, // Selects which state properties are merged into the component's props
    GameStore.actionCreators // Selects which action creators are merged into the component's props
)(Game as any);
