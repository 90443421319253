import * as React from 'react';
import * as GameStore from '../store/GameStore';

interface IGamePlayersProps {
    players: GameStore.IPlayer[],
}

export default class GamePlayers extends React.PureComponent<IGamePlayersProps> {
    public render() {
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-header">
                        Hráči
                    </div>
                    <ul className="list-group list-group-flush">
                        {this.props.players.map((value) => {
                            let leader = null;
                            let isMe = null;
                            let status = null;
                            if (value.isLeader) {
                                leader = (<span className="badge badge-warning">Šéf týmu</span>);
                            }
                            if (value.isMe) {
                                isMe = (<span title="Moje přezdívka">{"\u2605"} </span>)
                            }
                            else {
                                status = <span className={value.isDisconnected ? "text-danger" : "text-success"} title={value.isDisconnected ? "Odpojen" : "Připojen"}>{"\u25CF"} </span>;
                            }
                            return <li className="list-group-item" key={value.playerId}>{status}{isMe}{value.name} {leader}</li>
                        })}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}