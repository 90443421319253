import * as React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import './GameHeader.css';
import { IGame, GameStateEnum, CardRoleEnum } from '../store/GameStore';

interface IGameHeaderProps {
    isLoading: boolean,
    gameId: string,
    game: IGame | null,
    promotePlayer(): any,
    restartGame(): any,
}

export default class GameHeader extends React.PureComponent<IGameHeaderProps> {
    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container fluid>
                        <NavbarBrand tag={Link} to="/">Krycí jména</NavbarBrand>
                        {this.getState()}
                        {this.getButtons()}
                    </Container>
                </Navbar>
            </header>
        );
    }

    private getState() {
        const g = this.props.game;
        if (g == null) {
            if (this.props.isLoading) {
                return (<div className="text-center"><div className="spinner-border text-primary" role="status"></div></div>);
            }
            return;
        }

        let state: JSX.Element = (<span></span>);
        switch (g.state) {
            case GameStateEnum.WaitingForLeader:
                state = <span className="badge badge-success">Zahajte hru tím, že si šéfové týmů odkryjí herní plán</span>;
                break;

            case GameStateEnum.FinishedAssassin:
                state = <span className="badge badge-dark">Konec hry - odhalen VRAH!</span>;
                break;

            case GameStateEnum.FinishedAllBlue:
                state = <span className="badge badge-primary">Zvítězil MODRÝ tým!</span>;
                break;

            case GameStateEnum.FinishedAllRed:
                state = <span className="badge badge-danger">Zvítězil ČERVENÝ tým!</span>;
                break;
            case GameStateEnum.Started:
                const isLeader = g.players.some((p) => { return p.isLeader && p.isMe });
                const blueCards = g.cards.filter((c) => { return c.role === CardRoleEnum.Blue });
                const redCards = g.cards.filter((c) => { return c.role === CardRoleEnum.Red });
                const startTeam = isLeader && < span className = "small" title = "Začínající tým" > { "\u26BD"} </span>
                const blueTeamBegins = blueCards.length > redCards.length;
                return (
                    <h2>
                        {blueTeamBegins ? startTeam : null}
                        <span className="badge badge-primary">{blueCards.filter((c) => { return c.revealed }).length}{(isLeader && <span className="small"> / {blueCards.length}</span>)}</span>
                        <span> : </span>
                        <span className="badge badge-danger">{redCards.filter((c) => { return c.revealed }).length}{(isLeader && <span className="small"> / {redCards.length}</span>)}</span>
                        {!blueTeamBegins ? startTeam : null}
                    </h2>
                );
        }

        return (<h3 className="cn_game-state">{state}</h3>);
    }

    private getButtons() {
        const buttons: JSX.Element[] = [];
        const g = this.props.game;
        if (g == null) {
            return;
        }
        if (g.state === GameStateEnum.WaitingForLeader || g.state === GameStateEnum.Started) {
            if (!g.players.some((p) => { return p.isMe && p.isLeader })) {
                buttons.push(<button className="btn btn-danger" key="btnPromote" onClick={this.props.promotePlayer}>Odkrýt herní plán</button>);
            }
            else {
                buttons.push(<button className="btn btn-warning" key="btnRestart" onClick={this.props.restartGame}>Restartovat hru</button>);
            }
        }           
        else {
            buttons.push(<button className="btn btn-success" key="btnRestart" onClick={this.props.restartGame}>Restartovat hru</button>);
        }
        return <div>{buttons}</div>;
    }

}
