import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IGameState {
    isLoading: boolean;
    gameId: string | null;
    game: IGame | null;
}

export interface IGame {
    gameId: string;
    state: GameStateEnum;
    cards: ICard[];
    players: IPlayer[];
    journal: IJournalEntry[];
}

export enum GameStateEnum {
    WaitingForLeader = "WaitingForLeader",
    Started = "Started",
    FinishedAllRed = "FinishedAllRed",
    FinishedAllBlue = "FinishedAllBlue",
    FinishedAssassin = "FinishedAssassin"
}

export interface ICard {
    cardId: string;
    word: string;
    role: CardRoleEnum;
    revealed: boolean;
}

export enum CardRoleEnum {
    Unknown = "Unknown",
    Passerby = "Passerby",
    Blue = "Blue",
    Red = "Red",
    Assassin = "Assassin"
}

export interface IPlayer {
    playerId: string,
    name: string,
    isLeader: boolean,
    isMe: boolean,
    isDisconnected: boolean,
}

export interface IJournalEntry {
    timestamp: Date;
    data: any;
    type: JournalEntryTypeEnum;
}

export enum JournalEntryTypeEnum {
    PlayerConnected = "PlayerConnected",
    PlayerConnectedFromOtherDevice = "PlayerConnectedFromOtherDevice",
    PlayerReconnected = "PlayerReconnected",
    PlayerRenamed = "PlayerRenamed",
    PlayerDisconnected = "PlayerDisconnected",
    PlayerPromotedToLeader = "PlayerPromotedToLeader",
    CardRevealed = "CardRevealed",
    GameStateChanged = "GameStateChanged"
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IRequestGameAction {
    type: "REQUEST_GAME";
    gameId: string;
}

interface IReceiveGameAction {
    type: "RECEIVE_GAME";
    game: IGame;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = IRequestGameAction | IReceiveGameAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestGame: (gameId: string, connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch("api/game?gameId=" + encodeURIComponent(gameId) + "&connectionId=" + encodeURIComponent(connectionId))
            .then(response => response.json() as Promise<IGame>)
            .then(data => {
                dispatch({ type: "RECEIVE_GAME", game: data });
            });

        dispatch({ type: 'REQUEST_GAME', gameId: gameId });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IGameState = { game: null, gameId: null, isLoading: false };

export const reducer: Reducer<IGameState> = (state: IGameState | undefined, incomingAction: Action): IGameState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_GAME':
            return {
                gameId: action.gameId,
                game: state.game,
                isLoading: true,
            };
        case 'RECEIVE_GAME':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.game.gameId === state.gameId) {
                return {
                    gameId: action.game.gameId,
                    game: action.game,
                    isLoading: false,
                };
            }
            break;
    }

    return state;
};
