import * as React from 'react';
import * as GameStore from '../store/GameStore';

interface IGameJournalProps {
    journal: GameStore.IJournalEntry[]
}

export default class GameJournal extends React.PureComponent<IGameJournalProps> {
    public render() {
        return (<React.Fragment></React.Fragment>);
        return (
            <React.Fragment>
                {this.props.journal.map((value) => {
                    return <div key={"jentr" + value.timestamp}>{value.type}: {JSON.stringify(value.data)}</div>
                })}
            </React.Fragment>
        );
    }
}